.ticker-container {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  position: relative;
}

.ticker-content {
  display: inline-block;
  animation: scroll 30s linear infinite; /* Slow down animation */
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes ticker-scroll {
  0% {
    transform: translateX(100%); /* Start off-screen to the right */
    opacity: 0; /* Fully transparent */
  }
  10% {
    opacity: 1; /* Fully visible */
  }
  90% {
    opacity: 1; /* Fully visible */
  }
  100% {
    transform: translateX(-100%); /* Move off-screen to the left */
    opacity: 0; /* Fully transparent */
  }
}
